import { React, Helmet } from '@/vendor'
import { Mq, Grid, Gallery, Fs, Button } from '@/components'

const FAQ = [
	{
		headline: <>Where are you located, and how do you get&nbsp;there?</>,
		body: (
			<>
				<p>
					We are located at the South River Mill complex in Waynesboro, VA. It's an old industrial building
					with lots of quirks, but we've made it our own. GPS directions will take you to the South River Mill
					complex. Once you've passed the gate, head straight until you no longer can and make a right.
					Lightwell Survey is located down 200 feet on your left in building D53 right next to Fine Concrete.
				</p>
				<p>
					Our tasting room is at{' '}
					<a href="https://maps.app.goo.gl/W7WxS69iynbEhxbL8" target="_blank">
						200 W 12th St Unit D53, Waynesboro, VA 22980
					</a>
					. You can try to call our Google Phone @ 301.541.7328 (it MAY work)
				</p>
			</>
		),
		footer: (
			<p>
				<a href="https://maps.app.goo.gl/W7WxS69iynbEhxbL8" target="_blank">
					<img src={require('./visit-map.png')} alt="map" width="688" />
				</a>
			</p>
		),
	},
	{
		headline: <>When are you open?</>,
		body: (
			<p>
				We are open Saturdays 12-5 pm from April to November. The gate to enter the complex will only be open 
				during those times.
			</p>
		),
	},
	{
		headline: <>Do you have food?</>,
		body: (
			<p>
				We do not sell food in the tasting room, but you are more than welcome to bring a picnic if you'd like.
				We recommend stopping at{' '}
				<a href="https://greenwoodva.shop" target="_blank">
					Greenwood Grocery
				</a>
				near Crozet (if you're coming from Charlottesville) for some sandwiches to go.
			</p>
		),
	},
	{
		headline: <>Can I bring my dog?</>,
		body: (
			<p>
				Yes! Fido and friends are welcome. We are very dog-friendly. Cat suits are encouraged. Please, no more
				chickens.
			</p>
		),
	},
	{
		headline: <>Where are your bathrooms?</>,
		body: (
			<p>
				Our bathrooms are located in a separate building just across from us. Note: They're not ADA accessible
				and a little spooky.
			</p>
		),
	},
	{
		headline: <>Do I have to make reservations?</>,
		body: (
			<p>
				Reservations are encouraged (that way we can put our best clothes on and comb our hair before you get
				here), but not required. You can make a{' '}
				<a href="https://squareup.com/appointments/book/rvoul39wv4dcmp/LQFV0SYD1EBKS/services" target="_blank">
					reservation here
				</a>
				. We’re happy to take walk-ins until 4:30pm, 30 minutes before we close things up.
			</p>
		),
	},
	{
		headline: <>What’s included in the reservation?</>,
		body: (
			<p>
				Each of you get a guided tasting of at least 6 of our latest releases, shared by our super excellent
				tasting room team. We will have water available for essential hydration as well.
			</p>
		),
	},
	{
		headline: <>Do you rent your space for private events?</>,
		body: (
			<p>
				Let’s talk about it! Hit us up if you’re interested in hosting a private event in our tasting room:{' '}
				<span
					style={{
						whiteSpace: 'nowrap',
					}}
				>
					{' '}
					<a href="mailto:wines@lightwellsurvey.com">wines@lightwellsurvey.com</a>.
				</span>
			</p>
		),
	},
]

class VisitRoute extends React.PureComponent {
	render() {
		return (
			<>
				<Helmet>
					<title>Visit</title>
				</Helmet>
				<Mq>
					{(Mq) => (
						<>
							<Grid stacked={!Mq.large}>
								<Grid.Cell>
									<Grid.Content pad={2}>
										<Fs align="center" contained>
											<Fs variant="h2" tag="h2">
												Visit Us
											</Fs>
											<p>
											Join us in Waynesboro, VA (the Old Mill) for a look into our signature approach 
											to both winemaking and friendship. We're' open Saturdays 12-5pm from April to 
											November. Reservations are encouraged, especially for groups larger than six. 
											We are very dog friendly. For questions, contact us at {' '}
												<span
													style={{
														whiteSpace: 'nowrap',
													}}
												>
													at{' '}
													<a href="mailto:wines@lightwellsurvey.com">
														wines@lightwellsurvey.com
													</a>
													.
												</span>
											</p>
											<p>
												200 W. 12TH ST, UNIT# D53
												<br />
												Waynesboro, VA. 22980
												<br />
											</p>
										</Fs>
										<br />
										<br />
										<Button
											tag="a"
											href="https://book.squareup.com/appointments/rvoul39wv4dcmp/location/LQFV0SYD1EBKS/services/TFUXKT4SQJOILEBCISY4PF2V"
											target="_blank"
										>
											Book a Tasting
										</Button>
									</Grid.Content>
								</Grid.Cell>
								<Grid.Cell>
									<Gallery
										images={[
											require('./gallery/visit-gallery-1.jpg'),
											require('./gallery/visit-gallery-2.jpg'),
											require('./gallery/visit-gallery-3.jpg'),
											require('./gallery/visit-gallery-4.jpg'),
											require('./gallery/visit-gallery-5.jpg'),
											require('./gallery/visit-gallery-6.jpg'),
											require('./gallery/visit-gallery-7.jpg'),
										]}
									/>
								</Grid.Cell>
							</Grid>
							{FAQ.map(({ headline, body, footer }, idx) => (
								<Grid stacked key={`faq-${idx}`}>
									<Grid.Cell>
										<Grid.Content pad={2}>
											<Fs contained>
												<Fs variant="h2" tag="h2" align="center">
													{headline}
												</Fs>
												{body}
											</Fs>
											{footer && (
												<>
													<br />
													{footer}
												</>
											)}
										</Grid.Content>
									</Grid.Cell>
								</Grid>
							))}
						</>
					)}
				</Mq>
			</>
		)
	}
}

export default VisitRoute
